.footer-container {
    display: flex;
    justify-content: space-evenly;
    max-width: 70%;
    padding: 15px;
}

.button-group {
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none !important;
    color: black !important;
}
