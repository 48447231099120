.container {
    /* margin-top: 50px; */
    margin: 10px !important;
    padding-left: 7% !important;
    max-width: 70% !important;
}

.heading {
    color: #0d6efd;
}

.dialog-container {
    border: #ced4da 1px solid;
    border-radius: 5px;
    padding: 10px;
}

.form-group {
    margin-bottom: 20px;
    display: flex !important;
    align-items: baseline;
}

.form-control {
    height: 50px;
    font-size: 16px;
    border-radius: 5px;
    margin-right: 10px;
}

.btn {
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 20px;
}

.response-container {
    padding-top: 30px;
}
