.landing-card {
    box-shadow: 0 -2px 8px rgb(219, 218, 218) inset;
    border: none !important;
    border-radius: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    background-color: rgba(211, 211, 211, 0.637);
    width: 100%;
    padding: 5% 20%;
    font-family: serif;
}

.landing-card .mini-card {
    padding: 3%;
    margin: 10px;
    box-shadow: 3px 3px 5px darkgray;
    border-radius: 30px !important;
}

.landing-card .card-img-top {
    border-radius: 10px;
    margin-bottom: 20px;
}

.landing-card .card-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.3;
    align-self: flex-start;
    background-image: linear-gradient(to bottom right, rgb(6, 69, 85), rgb(15, 179, 151));
    background-clip: text;
    color: transparent;
}

.logo-card {
    border: none !important;
    border-radius: 0px !important;
    background-color: rgba(211, 211, 211, 0.637);
    font-family: serif;
    display: flex !important;
    flex-direction: row !important;
    margin: 10px !important;
    padding-left: 6%;
}

.logo-card img {
    width: 80px;
    height: 80px;
    margin-right: -20px;
}

.logo {
    font-weight: bolder !important;
    line-height: 1.3;
    align-self: flex-start;
    padding-top: 30px;
    color: rgb(6, 102, 86);
}

.landing-card .card-sub-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.3;
    align-self: flex-start;
    color: #4b54ff42;
    background-image: linear-gradient(to bottom right, rgb(6, 69, 85), rgb(15, 179, 151));
    background-clip: text;
    color: transparent;
}
.landing-card .flex-row {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
}

.landing-card .section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.landing-card .small-image {
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    border-radius: 25px;
}

.landing-card .card-body {
    padding: 0px !important;
    align-self: flex-start;
}

.landing-card .padding-5px {
    padding: 5px;
}

.landing-card .card-text {
    font-size: 1em;
    margin-bottom: 10px;
    text-align: left;
    line-height: 1.8;
}

.landing-card .btn {
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 25px;
    padding: 10px 30px;
    margin-top: 20px;
    background-image: linear-gradient(to bottom right, darkblue, rgba(0, 255, 255, 0.603));

    border: none;
}

.landing-card a {
    align-self: flex-start;
}

.landing-card .btn:hover {
    background-image: linear-gradient(to bottom right, darkblue, rgba(0, 255, 255, 0.445));
}

/* .landing-card table {
    box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.7), inset 2px 2px 4px rgba(189, 200, 223, 0.7);
    border-radius: 1em;
    border-collapse: separate;
}

.landing-card tr {
    display: flex;
    justify-content: space-between;
}

.landing-card td {
    padding-right: 200px;
    padding-left: 10px;
} */

/* .landing-card td:first-child {
    border-right: 1px solid black;
} */
