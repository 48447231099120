.login-form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
}

.login-form .form-floating {
    position: relative;
    margin-bottom: 10px;
}

.login-form input[type="email"],
.login-form input[type="password"] {
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.login-form label {
    color: #888;
    font-size: 14px;
}

.login-form button[type="submit"] {
    background-color: #007bff;
    border-color: #007bff;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
}

.login-form button[type="submit"]:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px;
    margin: auto;
    margin-top: 50px;
    max-width: 500px;
    border-radius: 10px;
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

/* Image from following site: */
/* https://gymdesk.com/blog/how-to-start-a-nutrition-program-at-your-gym/ */
.login-bg {
    background-image: url("https://gymdesk.com/blog/wp-content/uploads/2022/02/nutrition-program-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
    padding-top: 10px;
}

.login-heading {
    padding: 15px;
    border-radius: 10px;
}
