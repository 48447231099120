.container {
    margin-top: 50px;
}

.form-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.form-control {
    height: 50px;
    font-size: 16px;
    border-radius: 5px;
}

.btn {
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 20px;
}

.response-container {
    padding-top: 30px;
}
