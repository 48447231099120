/* image site: */
/* https://gymdesk.com/blog/how-to-start-a-nutrition-program-at-your-gym/  */
.signup-background {
    background-image: url("https://gymdesk.com/blog/wp-content/uploads/2022/02/nutrition-program-1.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    padding-top: 10px;
}

.signup-form-container {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px;
    margin: auto;
    margin-top: 50px;
    max-width: 500px;
    border-radius: 10px;
}

.signup-heading {
    text-align: center;
    margin-bottom: 20px;
    color: black;
}

.signup-form {
    display: flex;
    flex-direction: column;
}

.signup-form label {
    color: #6c757d;
}

.signup-form input[type="text"],
.signup-form input[type="email"],
.signup-form input[type="password"] {
    margin-bottom: 20px;
}

.signup-form button[type="submit"] {
    margin-top: 20px;
}

.signup-link {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
