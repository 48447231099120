.home-container {
    height: 100vh;
    background-color: #f1f1f1;
}

.home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #343a40;
    color: #fff;
}

.home-title {
    margin: 0;
    font-size: 36px;
}

.home-sign-out-btn {
    font-size: 18px;
}

.home-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 82px);
}

.home-button-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.home-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.home-button:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.home-gym-ai-btn {
    background-color: #28a745;
}

.home-nutrition-ai-btn {
    background-color: #ffc107;
}
